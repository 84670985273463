import React from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import styles from './style.module.scss'

const Footer: React.FC = () => {
  const { t } = useTranslation('common')

  return <div className={styles['footer-wrapper']} id='footer-contact'>
    <div className={styles.main}>
      <div className={styles.top}>
        <Image src='/images/components/logoGray.svg'
          width={90}
          height={80}
          alt=''
        />
        <div className={styles.center}>
          <div className={styles.item}>
            <div className={styles.title}>{t('法律条款')}</div>
            <a href="//www.shellpub.com/license/license_CN.txt" className={styles.link}>{t('服务条款')}</a>
            <a href="//www.shellpub.com/license/privacy_policy_CN.txt" className={styles.link}>{t('隐私条款')}</a>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>{t('联系我们')}</div>
            <a href="https://jq.qq.com/?_wv=1027&k=LAFcUNiM" className={styles.link}><i className={styles['icon-qq']} />971146821({t('群')})</a>
            <a href="mailto:service@shellpub.com" className={styles.link}><i className={styles['icon-email']} />service@shellpub.com</a>
          </div>
        </div>
        <div className={styles['focus-us-wrapper']}>
          <Image src='/images/components/officialAccountsQrcode.jpg'
            width={80}
            height={80}
            alt=''
          />
          <div className={styles['focus-text']}>{t('扫码关注我们')}</div>
        </div>
      </div>
      <div className={styles.bottom}>
        <a href='//www.shellpub.com' className={styles.text}>Copyright©2021 shellpub.com</a>
        <span className={styles['v-line']} />
        <a href="https://beian.miit.gov.cn" className={styles.text}>京ICP备15044984号-5</a>
      </div>
    </div>

  </div>
}

export default Footer
