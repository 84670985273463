import axios, { AxiosRequestConfig, AxiosError, AxiosPromise } from 'axios'
import qs from 'qs'

export const isDevelopment = process.env.NODE_ENV !== 'production'
export const port = process.env.PORT || 4000
export const host = process.env.HOST || 'test.threatbook-inc.cn'

// 重复请求取消
const pendingMap = new Map()

function getPendingKey (config: AxiosRequestConfig) {
  const { url, method, params } = config
  let { data } = config
  if (typeof data === 'string') data = { data } // response里面返回的config.data是个字符串对象
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&')
}

function addPending (config: AxiosRequestConfig) {
  const pendingKey = getPendingKey(config)
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken(cancel => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel)
      }
    })
}

function removePending (config: AxiosRequestConfig) {
  const pendingKey = getPendingKey(config)
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey)
    cancelToken(pendingKey)
    pendingMap.delete(pendingKey)
  }
}

function httpErrorStatusHandle (error: AxiosError) {
  // 处理被取消的请求
  if (axios.isCancel(error)) return console.error('请求的重复请求：' + error.message)
  let message = ''
  if (error && error.response) {
    // debugger
    switch (error.response.status) {
      case 400:
        message = '参数不正确！'
        break
      case 403:
        message = '您没有权限操作！'
        break
      case 404:
        message = `请求地址出错: ${error.response.config.url}`
        break // 在正确域名下
      default:
        message = '异常问题，请联系管理员！'
        break
    }
  }
  if (error.message.includes('timeout')) message = '网络请求超时！'
  if (error.message.includes('Network')) message = window.navigator.onLine ? '服务端异常！' : '您断网了！'

  console.log(message)
}

function isWindow () {
  try {
    if (window) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

export interface customOptionsType {
  repeatRequestCancel: boolean
}
export default function axiosInstance (
  axiosConfig: AxiosRequestConfig,
  customOptions?: customOptionsType
): AxiosPromise {
  const defaultConfigs: AxiosRequestConfig = {
    baseURL: isDevelopment
      ? isWindow() ? `${location.origin}/api-dev/api/v1` : `http://${host}${port ? ':' + port : ''}/api-dev/api/v1`
      : isWindow() ? `${location.origin}/api/v1` : `http://${host}${port ? ':' + port : ''}/api/v1`,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [data => qs.stringify(data)]
  }

  const instance = axios.create(defaultConfigs)

  const repeatRequestCancel = Object.assign(
    {
      repeatRequestCancel: true
    },
    customOptions
  ).repeatRequestCancel

  instance.interceptors.request.use(
    config => {
      // 带token
      // if(token) {
      // (config.headers as any).Authorization = 'token'
      // }
      repeatRequestCancel && removePending(config)
      addPending(config)
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    response => {
      removePending(response.config)
      return response.data
    },
    error => {
      error.config && removePending(error.config)
      httpErrorStatusHandle(error)
      return Promise.reject(error)
    }
  )

  return instance(axiosConfig)
}
