import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'next-i18next'
import classNames from 'classnames'
import { DOWNLOAD_URL } from 'lib/const'

import styles from './style.module.scss'

interface Iprops {
  show?: boolean // 是否展示下载条幅
}

const Banner: React.FC<Iprops> = (props) => {
  const [show, setShow] = useState<boolean>(props.show || false)
  const { t } = useTranslation('common')

  const eventDownLoad = () => {
    window.location.href = DOWNLOAD_URL
  }

  const eventClose = () => {
    setShow(false)
  }

  return <div className={classNames(styles.wrapper, { [styles.show]: show })}>
    <div className={styles.content}>
      <Trans i18nKey={'如果你想获得更好的查杀体验，建议{{下载}}我们的客户端'} ns='common'>
        如果你想获得更好的查杀体验，建议<span className={styles.download} onClick={eventDownLoad}><i className={styles.icon} />{{ 下载: '下载' }}</span>我们的客户端
      </Trans>
    </div>
    <span className={styles.close} onClick={eventClose} />
  </div>
}

export default Banner
