import request, { customOptionsType, isDevelopment } from 'lib/request'

// 获取首页数据
export const getTop = (params: any = {}, customOptions?: customOptionsType) => {
  return request({
    method: 'GET',
    url: '/top',
    params: params
  }, customOptions)
}

// 上传接口
export const uploadZip = (params: any, customOptions?: customOptionsType) => {
  return request({
    method: 'POST',
    url: '/zip',
    data: params || {}
  }, customOptions)
}

// 任务状态接口
export const getStatus = (tid: string, customOptions?: customOptionsType) => {
  return request({
    method: 'GET',
    url: '/resultpage',
    params: {
      tid
    }
  }, customOptions)
}

// 任务结果页面
export const getResult = (tid: string, customOptions?: customOptionsType) => {
  return request({
    method: 'GET',
    url: `/zip/tid/${tid}`
  }, customOptions)
}

// 最近列表接口
export const getRecent = (params: {
  limit: number
  page: number
}, customOptions?: customOptionsType) => {
  return request({
    method: 'GET',
    url: '/recent',
    params
  }, customOptions)
}

// 下载接口
export const downloadFile = (tid: string, customOptions?: customOptionsType) => {
  return request({
    method: 'GET',
    url: `/download/${tid}`,
    responseType: 'blob'
  }, customOptions)
}

// 下载接口
export const upload = (data: any, customOptions?: customOptionsType) => {
  return request({
    method: 'post',
    url: '/zip',
    data
  }, customOptions)
}

export const uploadUrl = isDevelopment ? '/api-dev/api/v1/zip' : '/api/v1/zip'
