import React, { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import { Upload, UploadProps } from 'antd'
import { useTranslation, Trans } from 'next-i18next'
import { formatFileSize } from 'lib/utils'
import { getStatus, uploadUrl } from 'lib/api'

import styles from './style.module.scss'

const { Dragger } = Upload
const MAX_SIZE = 10
interface Iprops {
  className?: string // 是否展示下载条幅
  initStatus?: UploadStatus
}

export enum UploadStatus {
  default = 'default',
  uploading = 'uploading',
  scanning = 'scanning',
  waitingScanning = 'waitingScanning',
  uploadErr = 'uploadErr',
  serviceErr = 'serviceErr',
}

enum ScanStage {
  Created = 'Created',
  Running = 'Running',
  Finished = 'Finished',
  Failed = 'Failed',
}

const MyUpload: React.FC<Iprops> = (props) => {
  const router = useRouter()
  const { t } = useTranslation('upload')
  const { className, initStatus } = props
  const [uploadState, setUploadState] = useState<UploadStatus>(initStatus || UploadStatus.default)
  const [uploadDisable, setUploadDisable] = useState<boolean>(false)
  const [uploadPercent, setUploadPercent] = useState<number>(0)
  const [scanPercent, setScanPercent] = useState<number>(0)
  const [fileData, setFileData] = useState<any>(null)
  const timerRef = useRef<any>()

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    switch (uploadState) {
      case UploadStatus.uploading:
        setUploadDisable(true)
        break
      case UploadStatus.scanning:
      case UploadStatus.waitingScanning:
        setUploadDisable(true)
        uploadPercentReset()
        break
      case UploadStatus.default:
      case UploadStatus.serviceErr:
      case UploadStatus.uploadErr:
      default:
        setUploadDisable(false)
        uploadPercentReset()
    }
  }, [uploadState])

  const uploadProps: UploadProps = {
    className: classnames(styles['dragger-wrapper'], styles[uploadState], 'dragger-wrapper'),
    accept: '.zip',
    name: 'userfile',
    disabled: uploadDisable,
    maxCount: 1,
    showUploadList: false,
    withCredentials: true,
    action: uploadUrl,
    beforeUpload: (file) => {
      const { size, type } = file
      const maxSize = 1024 * 1024 * MAX_SIZE
      if (type.match('zip')) {
        if (size < maxSize) {
          return true
        } else {
          setUploadState(UploadStatus.uploadErr)
          return false
        }
      } else {
        setUploadState(UploadStatus.uploadErr)
        return false
      }
    },
    onChange: (info) => {
      const { status, percent, response } = info.file

      if (response && response.code !== 0) {
        setUploadState(UploadStatus.serviceErr)
        return
      }

      if (status === 'uploading') {
        setFileData(info.file)
        setUploadPercent(percent || 0)
        setUploadState(UploadStatus.uploading)
      }
      if (status === 'done') {
        setFileData({ ...info.file })
        setUploadState(UploadStatus.scanning)
        getTaskStatus(info?.file?.response?.data?.tid)
      } else if (status === 'error') {
        setUploadState(UploadStatus.serviceErr)
      }
    }
  }

  const getTaskStatus = (tid: string) => {
    const startTime = Date.now()
    if (!tid) {
      return
    }

    timerRef.current = setInterval(() => {
      // 超时10min报错
      if (Date.now() - startTime > 10 * 60 * 1000) {
        clearInterval(timerRef.current)
      }

      getStatus(tid).then(res => {
        const { progress, stage } = res.data
        setScanPercent(progress)
        switch (stage) {
          case ScanStage.Created:
          case ScanStage.Running:
            setUploadState(UploadStatus.scanning)
            break
          case ScanStage.Finished:
            clearInterval(timerRef.current)
            setUploadState(UploadStatus.default)
            router.push(`/detail/${tid}`)
            break
          case ScanStage.Failed:
            setUploadState(UploadStatus.serviceErr)
            clearInterval(timerRef.current)
            break
          default:
            break
        }
      }).catch(e => {
        console.log(e)
      })
    }, 500)
  }

  const uploadPercentReset = () => {
    setUploadPercent(0)
    setScanPercent(0)
  }

  const renderDragContent = () => {
    switch (uploadState) {
      case 'uploadErr':
        return <>
          <div className={classnames(styles['upload-err-img'], styles['upload-img'])} />
          <div className={styles['upload-tip']}>
            <Trans i18nKey={'文件超出{{MAX_SIZE}M或文件不为zip，请检查文件后重新上传'} ns='upload' MAX_SIZE={MAX_SIZE}>
              文件超出{{ MAX_SIZE }}M或文件不为zip，请检查文件后重新上传
            </Trans>
          </div>
          <div className={styles.btn}>{t('重新上传')}</div>
        </>
      case 'uploading':
        return <>
          <div className={classnames(styles['upload-uploading-img'], styles['upload-img'])} />
          <div className={styles['file-info']}>
            <span className={styles.name}>{fileData.name}</span>
            <span className={styles.splitline}>/</span>
            <span className={styles.size}>{formatFileSize(fileData.size)}</span>
          </div>
          <ProgressLine percent={uploadPercent} />
        </>
      case 'waitingScanning':
        return <>
          <div className={classnames(styles['upload-waiting-scanning-img'], styles['upload-img'])} />
          <div className={styles['upload-tip']}>{t('文件扫描排队中，请耐心等待...')}</div>
        </>
      case 'scanning':
        return <>
          <div className={classnames(styles['upload-scanning-img'], styles['upload-img'])} />
          <div className={styles['upload-tip']}>{t('正在扫描，马上就好')}</div>
          <ProgressLine percent={scanPercent} />
        </>
      case 'serviceErr':
        return <>
          <div className={classnames(styles['upload-service-err-img'], styles['upload-img'])} />
          <div className={styles['upload-tip']}>{t('服务目前不可用，如有问题请联系 service@shellpub.com')}</div>
          <div className={styles.btn}>{t('重新上传')}</div>
        </>
      case 'default':
      default:
        return <>
          <div className={classnames(styles['default-img'], styles['upload-img'])} />
          <div className={styles['upload-tip']}>
            <Trans i18nKey={'请拖拽zip文件到区域内，最大支持{{MAX_SIZE}}MB'} ns='upload' MAX_SIZE={MAX_SIZE}>
              请拖拽zip文件到区域内，最大支持{{ MAX_SIZE }}MB
            </Trans></div>
        </>
    }
  }

  return (
    <div className={classnames(styles.wrapper, className)}>
      <Dragger {...uploadProps}>
        {renderDragContent()}
      </Dragger>

      <style global jsx>{`
        .dragger-wrapper.ant-upload.ant-upload-drag .ant-upload {
          padding: 0 22px;
        }
        .dragger-wrapper.ant-upload.ant-upload-drag {
          border: none;
        }
        .dragger-wrapper .ant-upload.ant-upload-drag {
          background-color: #fff;
        }
        .dragger-wrapper.ant-upload.ant-upload-drag .ant-upload-drag-container {
          display: block
        }

      `}</style>
    </div>
  )
}

export default MyUpload

interface IProgressProps {
  percent?: number,
  className?: string,
}

export const ProgressLine: React.FC<IProgressProps> = ({ percent = 0, className = '' }) => {
  return <div className={classnames(styles['pogress-wrapper'], styles[className])}>
    <div className={styles.line}><div className='active-line' /></div>
    <div className={styles.text}>{percent.toFixed(2)}%</div>

    <style jsx>{`
      .active-line {
        background: #f56122;
        border-radius: 10px;
        height: 100%;
        width: ${percent}%;
      }
    `}</style>
  </div>
}
