import React from 'react'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import Banner from 'components/banner'
import HeaderNav from 'components/headerNav'
import Footer from 'components/footer'

import styles from './style.module.scss'

interface Iprops {
  show?: boolean
  children?: React.ReactNode
}

const Layout: React.FC<Iprops> = ({ children, show = false }) => {
  const { t } = useTranslation('common')
  return <div className={styles['layout-wrapper']}>
    <Head>
      <title>{t('SHELLPUB.COM在线查杀')}</title>
      <meta name="keywords" content={t('webshell查杀,webshell检测,web查杀,河马查杀,河马,webshell在线查杀,免费查杀,永久免费,在线杀毒,在线杀毒网页版,在线后门检测,后门检测,网站后门查杀,网站后门在线查杀,在线查杀,源码检测')} />
      <meta name="description" content={t('shellpub.com专注webshell查杀,免费查杀软件,web查杀,webshell在线查杀,在线检测网站后门，速度快，误报低，准确度高')} />
    </Head>
    <Banner show={show}/>
    <HeaderNav />
    {children}
    <Footer />
  </div>
}

export default Layout
