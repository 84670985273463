import React, { useEffect } from 'react'
import type { NextPage, NextComponentType } from 'next'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import Image from 'next/image'
import Layout from 'components/layout'
import QA from 'components/question&answer'
import MyUpload from 'components/upload'
import { getTop } from 'lib/api'

import styles from './style.module.scss'
export const Feature: NextComponentType = () => {
  const { t } = useTranslation('home')

  const featureData = [
    {
      feature: t('简单'),
      desc: t('拖拽上传到浏览器即可检测'),
      icon: '/images/pages/index/easy.svg'
    },
    {
      feature: t('快速'),
      desc: t('10MB的ZIP压缩包仅需2分钟'),
      icon: '/images/pages/index/fast.svg '
    },
    {
      feature: t('准确'),
      desc: t('特征+深度+机器学习+云多引擎结果准确'),
      icon: '/images/pages/index/accurate.svg'
    }
  ]

  return <div className={styles['feature-wrapper']}>
    <div className={styles.title}>{t('我们的特点')}</div>
    <div className={styles['feature-list']}>
      {
        featureData.map(item => {
          return <div key={item.feature} className={styles.item}>
            <Image
              className={styles.icon}
              src={item.icon}
              width={300}
              height={200}
              alt=''
            />
            <div className={styles.feature}>{item.feature}</div>
            <div className={styles.desc}>{item.desc}</div>
          </div>
        })
      }
    </div>
  </div>
}

export const LatestThreat: React.FC<{ data: string[] }> = ({ data }) => {
  const { t } = useTranslation('home')

  return <div className={styles['latest-threat-wrapper']}>
    <div className={styles['top-line']}>
      <div className={styles.text} >{t('最新后门威胁')}</div>
      <Link href='/history'><a className={styles.more}>{t('查看更多历史')}</a></Link>
    </div>
    <div className={styles.list}>
      {
        data.map(item => <div key={item} className={styles.item}>
          <Link href={`/detail/${item}`}><a>{item}</a></Link>
        </div>)
      }
    </div>
  </div>
}

export const UploaderWrapper: React.FC<any> = ({ initStatus = 'default' }) => {
  const { t } = useTranslation(['common', 'home'])

  return <div className={styles['upload-wrapper']}>
    <div className={styles.title}>{t('在线查杀', { ns: 'home' })}</div>
    <div className={styles.desc}>{t('SHELLPUB 专注查杀 WEBSHELL，免费好用的在线 WEBSHELL 查杀工具', { ns: 'home' })}</div>
    <div className={styles.arrow} />
    <MyUpload className={styles.upload} initStatus={initStatus} />
  </div>
}

const Home: NextPage<any> = (props) => {
  const { data } = props

  return (
    <Layout show={true}>
      <UploaderWrapper />
      <LatestThreat data={data} />
      <Feature />
      <QA />
    </Layout>
  )
}

export async function getServerSideProps(context: any) {
  const { locale } = context
  const res: any = await getTop()
  let data = []

  if (res?.data?.length) {
    data = res.data.map((item: any) => item.tid)
  }

  return {
    props: {
      data: data,
      ...(await serverSideTranslations(locale, ['common', 'home', 'upload']))
    }
  }
}

export default Home
