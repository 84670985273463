import React from 'react'
import { DOWNLOAD_URL } from 'lib/const'
import { useTranslation, Trans } from 'next-i18next'
import { NextComponentType } from 'next'

import styles from './style.module.scss'

const QA: NextComponentType = () => {
  const { t } = useTranslation('home')

  const operateStepData = [
    {
      text: t('移除网站中的恶意文件'),
      icon: '/images/components/trash.svg'
    },
    {
      text: t('移除后，在重新打包并上传检测是否存在恶意文件'),
      icon: '/images/components/rebuild.svg'
    },
    {
      text: t('更改你的密码（如ftp密码，网站后台密码，数据库密码，系统密码等）'),
      icon: '/images/components/resetPassword.svg'
    },
    {
      text: t('定期检查网站是否存在后门以及安全问题，确保网站安全'),
      icon: '/images/components/checkWebsite.svg'
    }
  ]

  const QAData = [
    {
      question: t('为什么上传会失败？'),
      answer: [
        <p className={styles['answer-item']} key='1'>{t('上传失败，原因可能有四种：')} </p>,
        <p className={styles['answer-item']} key='2'>{t('1.上传的文件大小超过了10MB, 文件多扫描时请耐心等待')} </p>,
        <p className={styles['answer-item']} key='3'>{t('2.上传的文件格式不正确，不是标准的zip包文件')} </p>,
        <p className={styles['answer-item']} key='4'>{t('3.网络不稳定，文件上传不完全；这种请刷新并重新上传')}</p>,
        <p className={styles['answer-item']} key='5'>{t('4.浏览器版本太低，推荐使用chrome或者包含chrome内核的第三方浏览器（如：360浏览器）')}</p>
      ]
    }, {
      question: t('结果不准确？'),
      answer: <p className={styles['answer-item']}>
        <Trans i18nKey={'如果您发现有误报、漏报的情况，可以将误报、漏报的样本压缩后发送到feedback@shellpub.com，并在邮件标题中注明【漏报】或【误报】，其他任何关于webshell查杀方面的问题，均可以{{联系我们}}'} ns='home'>
          如果您发现有误报、漏报的情况，可以将误报、漏报的样本压缩后发送到feedback@shellpub.com，并在邮件标题中注明【漏报】或【误报】，其他任何关于webshell查杀方面的问题，均可以<a href='#footer-contact' className={styles.contact}>{{ 联系我们: '联系我们' } as any}</a>
        </Trans>
      </p>
    }, {
      question: t('这个服务是免费的吗？'),
      answer: <p className={styles['answer-item']}>
        <Trans i18nKey={'目前不收费，多次上传不限制数量，{{下载客户端}} 会体验更好'} ns='home'>
          目前不收费，多次上传不限制数量，<a href={DOWNLOAD_URL} className={styles.contact}>{{ '下载客户端': '下载客户端' } as any}</a>会体验更好
        </Trans></p>
    }, {
      question: t('河马是如何检测webshell的？'),
      answer: <p className={styles['answer-item']}>{t('河马在线查杀，是河马查杀客户端的在线版本，最新的检测能力均会首先集成到在线版上；河马查杀自主开发的webshell检测引擎拥有完整的知识产权，采用静态分析、动态分析、特征匹配、机器学习多种技术检测webshell')}</p>
    }
  ]

  return <>
    <div className={styles['step-wrapper']}>
      <div className={styles.title}>{t('如果检测到恶意文件，该怎么操作')}</div>
      <div className={styles['list-wrapper']}>
        {
          operateStepData.map((item, index) => {
            return <React.Fragment key={index}><div className={styles.item}>
              <div className={styles.icon} style={{ backgroundImage: `url(${item.icon})` }} />
              <div className={styles.num}>{index + 1}</div>
              <div className={styles.text}>{item.text}</div>
            </div>
              <div className={styles.arrow}></div>
            </React.Fragment>
          })
        }
      </div>
    </div>
    <div className={styles['qa-wrapper']}>
      <div className={styles.title}>Q&A</div>
      <div className={styles['list-wrapper']}>
        {
          QAData.map((item, index) => {
            return <React.Fragment key={index}>
              <div className={styles.question}>{item.question}</div>
              <div className={styles.answer}>{item.answer}</div>
            </React.Fragment>
          })
        }
      </div>
    </div>
  </>
}

export default QA
