import React, { useEffect, useState } from 'react'
import { NextComponentType } from 'next'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { Dropdown, Space, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import Image from 'next/image'
import classNames from 'classnames'
import { HOME_URL, DOWNLOAD_URL } from 'lib/const'

import styles from './style.module.scss'

const LAN = [{
  key: 'zh',
  label: '简体中文'
},
{
  key: 'en',
  label: 'English'
}]

const Header: NextComponentType = () => {
  const router = useRouter()
  const [lan, setLan] = useState<string>(LAN[0].key)
  const { t, i18n } = useTranslation('common')

  const changeLan = (lan: string) => {
    router.push(router.pathname, router.asPath, { locale: lan })
    i18n.changeLanguage(lan)
  }

  useEffect(() => {
    setLan(i18n.language)
  }, [router.pathname])

  const menu = (
    <Menu>
      {
        LAN.map(item => {
          return <Menu.Item key={item.key}>
            <span onClick={() => changeLan(item.key)}>{item.label}</span>
          </Menu.Item>
        })
      }
    </Menu>
  )
  return <div className={styles['header-wrapper']}>
    <div className={styles.left}>
      <Link href='/'>
        <a>
          <Image src="/images/components/logoOrange.svg"
            width={170}
            height={30}
            alt='logo'
          />
        </a>
      </Link>
    </div>
    <div className={styles.right}>
      <a className={styles.link} href={HOME_URL}>{t('官方网站')}</a>
      <a className={styles.link} href={DOWNLOAD_URL}>{t('客户端下载')}</a>
      <Link href='/'><a className={classNames(styles.link, styles.actived)}>{t('在线查杀')}</a></Link>
      <Dropdown overlay={menu}>
        <span className={styles.lan}>{lan === 'zh' ? 'English' : '简体中文'} <DownOutlined /></span>
      </Dropdown>

    </div>
  </div>
}

export default Header
